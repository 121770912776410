import Wix from '../sdk/WixSdkWrapper';
import { logger } from './biLogger';
import { baseUtils } from './baseUtils.js';
import * as fedopsLogger from '@wix/fedops-logger';
import window from '../sdk/windowWrapper';
export function timingMark(name) {
  try {
    window.performance && window.performance.mark && window.performance.mark(name);
  } catch (e) {
    console.error('Error in timingMark', e);
  }
}
export function timingMeasure(measureName, startMarkName, endMarkName) {
  try {
    if (window.performance && performance.measure) {
      if (endMarkName) {
        // performance.measure is not allowed to get undefined in ie
        performance.measure(measureName, startMarkName, endMarkName);
      } else if (startMarkName) {
        performance.measure(measureName, startMarkName);
      } else {
        performance.measure(measureName);
      }
    }
  } catch (e) {
    console.error('Error in timingMeasure', e);
  }
}
export function itemLoadStart() {
  try {
    if (!window.itemLoadStart) {
      window.itemLoadStart = true;
      timingMark('itemLoadStart');
    }
  } catch (e) {//
  }
}
export function itemLoaded() {
  try {
    if (baseUtils.isTest() || baseUtils.isOOI()) {
      return;
    }

    if (!window.proGalleryItemLoaded) {
      timingMeasure('firstItemLoaded', 'itemLoadStart');
      window.proGalleryItemLoaded = true;

      if (window.performance && performance.getEntriesByName) {
        var _performance$getEntri = performance.getEntriesByName('firstItemLoaded')[0],
            startTime = _performance$getEntri.startTime,
            duration = _performance$getEntri.duration;
        logger.trackBi(logger.biEvents.pro_gallery_load_uou, {
          endpoint: 'itemLoadStart',
          loading_time: Math.round(startTime)
        });
        logger.trackBi(logger.biEvents.pro_gallery_load_uou, {
          endpoint: 'firstItemLoadDuration',
          loading_time: Math.round(duration + startTime)
        });
      }
    }
  } catch (e) {// console.error('apploaded measure', e);
  }
}
export function appLoaded() {
  try {
    if (baseUtils.isTest() || baseUtils.isOOI()) {
      return;
    }

    if (!window.appLoaded) {
      window.appLoaded = true;
      Wix.Performance.applicationLoaded();

      if (window.fedopsAppName) {
        console.log(window.fedopsAppName, 'Loaded');

        var _fedopsLogger = fedopsLogger.create(window.fedopsAppName);

        if (_fedopsLogger) {
          _fedopsLogger.appLoaded();
        }
      }
    }
  } catch (e) {// console.log('fedopsLogger: apploaded failed ', appName, e);
  }
}
export function appPartiallyLoaded() {
  try {
    if (baseUtils.isTest() || baseUtils.isOOI()) {
      return;
    }

    if (!window.proGalleryPartiallyLoaded) {
      window.proGalleryPartiallyLoaded = true;
      console.log('Pro Gallery Partially Loaded');
      Wix.Performance.applicationLoadingStep(1, 'Pro Gallery is Loading');
    }
  } catch (e) {// console.log('Wix performance: appPartiallyLoaded failed ', _appName, e);
  }
}