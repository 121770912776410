function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { getProGalleryStylesImp } from './proGalleryStyleBuilder';
export var HEIGHT_SET = {
  BY_CONTENT: 'content',
  BY_PARENT: 'parent',
  BY_BOTH: 'both'
};
var SCROLL_DIRECTION = {
  VERTICAL: 0,
  HORIZONTAL: 1
};
export var getProGalleryHeightSource = function getProGalleryHeightSource(wixStyles) {
  try {
    var defaultOptions = {
      isMobile: false,
      isStoreGallery: false,
      isOnBoarding: false
    };

    var spreadStyles = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, wixStyles.booleans), wixStyles.numbers), wixStyles.colors), wixStyles.fonts), wixStyles.manual);

    var galleryStyles = getProGalleryStylesImp(spreadStyles, true, _objectSpread({}, defaultOptions)); // wix to gallery
    // const presetStyles = addPresetStyles(galleryStyles); // preset funnel
    // const processedStyles = addLayoutStyles(presetStyles); // final processed styles
    // DESIGNED_PRESET: -2
    // EMPTY: -1,
    // COLLAGE: 0,
    // MASONRY: 1,
    // GRID: 2,
    // THUMBNAIL: 3,
    // SLIDER: 4,
    // SLIDESHOW: 5,
    // PANORAMA: 6,
    // COLUMN: 7,
    // MAGIC: 8,
    // FULLSIZE: 9,
    // BRICKS: 10,
    // MIX: 11,
    // ALTERNATE: 12,

    switch (galleryStyles.galleryLayout) {
      case -1:
      case 1:
      case 6:
      case 8:
      case 10:
      case 11:
      case 12:
        galleryStyles.scrollDirection = SCROLL_DIRECTION.VERTICAL;
        break;

      case 3:
      case 4:
      case 5:
      case 7:
      case 9:
        galleryStyles.scrollDirection = SCROLL_DIRECTION.HORIZONTAL;
        break;

      case 0:
      case 2:
        galleryStyles.scrollDirection = galleryStyles.scrollDirection === undefined ? SCROLL_DIRECTION.VERTICAL : galleryStyles.scrollDirection;
        break;

      case -2:
        // Designed preset - taking 'scrollDirection' and 'enableInfiniteScroll' from jsonStyleParams
        var _JSON$parse = JSON.parse(galleryStyles.jsonStyleParams),
            parsedScrollDirection = _JSON$parse.scrollDirection,
            parsedEnableInfiniteScroll = _JSON$parse.enableInfiniteScroll;

        galleryStyles.scrollDirection = parsedScrollDirection === undefined ? SCROLL_DIRECTION.VERTICAL : Number(parsedScrollDirection);
        galleryStyles.enableInfiniteScroll = parsedEnableInfiniteScroll === undefined ? galleryStyles.enableInfiniteScroll : parsedEnableInfiniteScroll;
        break;

      default:
        galleryStyles.scrollDirection = SCROLL_DIRECTION.VERTICAL;
        break;
    } // const processedStyles = addLayoutStyles(galleryStyles); // final processed styles


    if (galleryStyles.scrollDirection === SCROLL_DIRECTION.VERTICAL && !(galleryStyles.enableInfiniteScroll === false)) {
      return HEIGHT_SET.BY_CONTENT;
    } else {
      return HEIGHT_SET.BY_PARENT;
    }
  } catch (e) {
    console.error('Cant get pro gallery height source', e);
    return null;
  }
};