var calculateTextBoxHeightMode = {
  AUTOMATIC: {
    pgStyleParam: 'AUTOMATIC',
    wixStyleParam: 0
  },
  MANUAL: {
    pgStyleParam: 'MANUAL',
    wixStyleParam: 1
  }
};
var expandAnimation = {
  NO_EFFECT: {
    pgStyleParam: 'NO_EFFECT',
    wixStyleParam: 0
  },
  EXPAND: {
    pgStyleParam: 'EXPAND',
    wixStyleParam: 1
  },
  FADE_IN: {
    pgStyleParam: 'FADE_IN',
    wixStyleParam: 2
  },
  ZOOM: {
    pgStyleParam: 'ZOOM',
    wixStyleParam: 3
  }
};
var galleryHorizontalAlign = {
  'flex-start': {
    pgStyleParam: 'flex-start',
    wixStyleParam: 0
  },
  center: {
    pgStyleParam: 'center',
    wixStyleParam: 1
  },
  'flex-end': {
    pgStyleParam: 'flex-end',
    wixStyleParam: 2
  }
};
var gallerySizeType = {
  smart: {
    pgStyleParam: 'smart',
    wixStyleParam: 0
  },
  px: {
    pgStyleParam: 'px',
    wixStyleParam: 1
  },
  ratio: {
    pgStyleParam: 'ratio',
    wixStyleParam: 2
  }
};
var galleryThumbnailsAlignment = {
  bottom: {
    pgStyleParam: 'bottom',
    wixStyleParam: 0
  },
  left: {
    pgStyleParam: 'left',
    wixStyleParam: 1
  },
  top: {
    pgStyleParam: 'top',
    wixStyleParam: 2
  },
  right: {
    pgStyleParam: 'right',
    wixStyleParam: 2
  }
};
var galleryVerticalAlign = {
  'flex-start': {
    pgStyleParam: 'flex-start',
    wixStyleParam: 0
  },
  center: {
    pgStyleParam: 'center',
    wixStyleParam: 1
  },
  'flex-end': {
    pgStyleParam: 'flex-end',
    wixStyleParam: 2
  }
};
var hoveringBehaviour = {
  APPEARS: {
    pgStyleParam: 'APPEARS',
    wixStyleParam: 0
  },
  DISAPPEARS: {
    pgStyleParam: 'DISAPPEARS',
    wixStyleParam: 1
  },
  NO_CHANGE: {
    pgStyleParam: 'NO_CHANGE',
    wixStyleParam: 2
  }
};
var imageHoverAnimation = {
  NO_EFFECT: {
    pgStyleParam: 'NO_EFFECT',
    wixStyleParam: 0
  },
  ZOOM_IN: {
    pgStyleParam: 'ZOOM_IN',
    wixStyleParam: 1
  },
  BLUR: {
    pgStyleParam: 'BLUR',
    wixStyleParam: 2
  },
  GRAYSCALE: {
    pgStyleParam: 'GRAYSCALE',
    wixStyleParam: 3
  },
  SHRINK: {
    pgStyleParam: 'SHRINK',
    wixStyleParam: 4
  },
  INVERT: {
    pgStyleParam: 'INVERT',
    wixStyleParam: 5
  },
  COLOR_IN: {
    pgStyleParam: 'COLOR_IN',
    wixStyleParam: 6
  },
  DARKENED: {
    pgStyleParam: 'DARKENED',
    wixStyleParam: 7
  }
};
var imageInfoType = {
  NO_BACKGROUND: {
    pgStyleParam: 'NO_BACKGROUND',
    wixStyleParam: 0
  },
  ATTACHED_BACKGROUND: {
    pgStyleParam: 'ATTACHED_BACKGROUND',
    wixStyleParam: 1
  },
  SEPARATED_BACKGROUND: {
    pgStyleParam: 'SEPARATED_BACKGROUND',
    wixStyleParam: 2
  },
  DONT_SHOW: {
    pgStyleParam: 'DONT_SHOW',
    wixStyleParam: 0 // based on proGalleryStyleBuilder, line 736

  }
};
var imageLoadingMode = {
  BLUR: {
    pgStyleParam: 'BLUR',
    wixStyleParam: 0
  },
  COLOR: {
    pgStyleParam: 'COLOR',
    wixStyleParam: 1
  }
};
var imageLoadingWithColorMode = {
  PICKED_COLOR: {
    pgStyleParam: 'PICKED_COLOR',
    wixStyleParam: 0
  },
  MAIN_COLOR: {
    pgStyleParam: 'MAIN_COLOR',
    wixStyleParam: 1
  }
};
var imageRatioType = {
  FIXED: {
    pgStyleParam: 'FIXED',
    wixStyleParam: 0
  },
  RESPONSIVE: {
    pgStyleParam: 'RESPONSIVE',
    wixStyleParam: 1
  }
};
var overlayAnimation = {
  NO_EFFECT: {
    pgStyleParam: 'NO_EFFECT',
    wixStyleParam: 0
  },
  FADE_IN: {
    pgStyleParam: 'FADE_IN',
    wixStyleParam: 1
  },
  EXPAND: {
    pgStyleParam: 'EXPAND',
    wixStyleParam: 2
  },
  SLIDE_UP: {
    pgStyleParam: 'SLIDE_UP',
    wixStyleParam: 3
  },
  SLIDE_RIGHT: {
    pgStyleParam: 'SLIDE_RIGHT',
    wixStyleParam: 4
  }
};
var scrollAnimation = {
  NO_EFFECT: {
    pgStyleParam: 'NO_EFFECT',
    wixStyleParam: 0
  },
  FADE_IN: {
    pgStyleParam: 'FADE_IN',
    wixStyleParam: 1
  },
  GRAYSCALE: {
    pgStyleParam: 'GRAYSCALE',
    wixStyleParam: 2
  },
  SLIDE_UP: {
    pgStyleParam: 'SLIDE_UP',
    wixStyleParam: 3
  },
  EXPAND: {
    pgStyleParam: 'EXPAND',
    wixStyleParam: 4
  },
  SHRINK: {
    pgStyleParam: 'SHRINK',
    wixStyleParam: 5
  },
  ZOOM_OUT: {
    pgStyleParam: 'ZOOM_OUT',
    wixStyleParam: 6
  },
  ONE_COLOR: {
    pgStyleParam: 'ONE_COLOR',
    wixStyleParam: 7
  }
};
var slideAnimation = {
  SCROLL: {
    pgStyleParam: 'SCROLL',
    wixStyleParam: 0
  },
  FADE: {
    pgStyleParam: 'FADE',
    wixStyleParam: 1
  },
  DECK: {
    pgStyleParam: 'DECK',
    wixStyleParam: 2
  }
};
var titlePlacement = {
  SHOW_BELOW: {
    pgStyleParam: 'SHOW_BELOW',
    wixStyleParam: 0
  },
  SHOW_ON_HOVER: {
    pgStyleParam: 'SHOW_ON_HOVER',
    wixStyleParam: 1
  },
  SHOW_ABOVE: {
    pgStyleParam: 'SHOW_ABOVE',
    wixStyleParam: 2
  },
  SHOW_ON_THE_RIGHT: {
    pgStyleParam: 'SHOW_ON_THE_RIGHT',
    wixStyleParam: 3 // TODO: What is the real value?

  },
  SHOW_ON_THE_LEFT: {
    pgStyleParam: 'SHOW_ON_THE_LEFT',
    wixStyleParam: 4 // TODO: What is the real value?

  },
  ALTERNATE_HORIZONTAL: {
    pgStyleParam: 'ALTERNATE_HORIZONTAL',
    wixStyleParam: 5 // TODO: What is the real value?

  },
  ALTERNATE_VERTICAL: {
    pgStyleParam: 'ALTERNATE_VERTICAL',
    wixStyleParam: 6 // TODO: What is the real value?

  }
};
var videoPlay = {
  hover: {
    pgStyleParam: 'hover',
    wixStyleParam: 0
  },
  auto: {
    pgStyleParam: 'auto',
    wixStyleParam: 1
  },
  onClick: {
    pgStyleParam: 'onClick',
    wixStyleParam: 2
  }
};
export default {
  calculateTextBoxHeightMode: calculateTextBoxHeightMode,
  expandAnimation: expandAnimation,
  galleryHorizontalAlign: galleryHorizontalAlign,
  gallerySizeType: gallerySizeType,
  galleryThumbnailsAlignment: galleryThumbnailsAlignment,
  galleryVerticalAlign: galleryVerticalAlign,
  hoveringBehaviour: hoveringBehaviour,
  imageHoverAnimation: imageHoverAnimation,
  imageInfoType: imageInfoType,
  imageLoadingMode: imageLoadingMode,
  imageLoadingWithColorMode: imageLoadingWithColorMode,
  imageRatioType: imageRatioType,
  overlayAnimation: overlayAnimation,
  scrollAnimation: scrollAnimation,
  slideAnimation: slideAnimation,
  titlePlacement: titlePlacement,
  videoPlay: videoPlay
};