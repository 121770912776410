// Currently not in use
import { default as CONVERTER_CONSTS } from './consts';
var buildWixStyleParam = {
  sameTypeSameName: function sameTypeSameName(type, value) {
    try {
      var parsedValue;

      switch (type) {
        case 'boolean':
          parsedValue = value !== undefined ? value : false;
          break;

        case 'number':
          parsedValue = value !== undefined ? Number(value) : 0;
          break;

        case 'font':
          parsedValue = value !== undefined ? value : {
            editorKey: 'font_5',
            size: 22
          };
          break;

        case 'color':
          parsedValue = value !== undefined ? value : 'color-1';
          break;

        default:
          break;
      }

      return {
        type: type,
        value: parsedValue
      };
    } catch (e) {
      return {
        type: type,
        value: undefined
      };
    }
  },
  numberTypeWithTransformation: function numberTypeWithTransformation(type, value, transformBy) {
    try {
      return {
        type: type,
        value: value !== undefined ? eval("" + value + transformBy) : 0
      };
    } catch (e) {
      return {
        type: type,
        value: undefined
      };
    }
  },
  booleanToNumber: function booleanToNumber(type, value, trueValue, falseValue) {
    try {
      return {
        type: type,
        value: value !== undefined ? value === true ? trueValue : falseValue : 0
      };
    } catch (e) {
      return {
        type: type,
        value: undefined
      };
    }
  },
  stringToNumber: function stringToNumber(type, value, options) {
    try {
      return {
        type: type,
        value: value !== undefined ? options[value].wixStyleParam : undefined
      };
    } catch (e) {
      return {
        type: type,
        value: undefined
      };
    }
  }
}; // CASE 1: Style params that have the same name, and the same type

var SAME_TYPE_AND_SMAE_NAME = [// ************* boolean *************
// { key: 'allowContextMenu', type: 'boolean' },
// { key: 'allowDescription', type: 'boolean' },
// { key: 'allowDownload', type: 'boolean' },
{
  key: 'allowSlideshowCounter',
  type: 'boolean'
}, // { key: 'allowSocial', type: 'boolean' },
// { key: 'allowTitle', type: 'boolean' },
// { key: 'alwaysShowHover', type: 'boolean' },
{
  key: 'itemEnableShadow',
  type: 'boolean'
}, // { key: 'loveButton', type: 'boolean' },
{
  key: 'showArrows',
  type: 'boolean'
}, // { key: 'showVideoPlayButton', type: 'boolean' },
// { key: 'useCustomButton', type: 'boolean' },
{
  key: 'videoLoop',
  type: 'boolean'
}, {
  key: 'videoSound',
  type: 'boolean'
}, // ************* number *************
{
  key: 'arrowsSize',
  type: 'number'
}, {
  key: 'autoSlideshowInterval',
  type: 'number'
}, {
  key: 'cubeRatio',
  type: 'number'
}, // TODO: There issue with cubeRatio
// { key: 'customButtonBorderRadius', type: 'number' },
// { key: 'customButtonBorderWidth', type: 'number' },
{
  key: 'designedPresetId',
  type: 'number'
}, // not coming from defaultStyles
{
  key: 'fixedColumns',
  type: 'number'
}, {
  key: 'galleryLayout',
  type: 'number'
}, // not coming from defaultStyles
{
  key: 'galleryMargin',
  type: 'number'
}, {
  key: 'gallerySize',
  type: 'number'
}, {
  key: 'gridStyle',
  type: 'number'
}, {
  key: 'groupSize',
  type: 'number'
}, {
  key: 'groupsPerStrip',
  type: 'number'
}, {
  key: 'imageMargin',
  type: 'number'
}, {
  key: 'imageQuality',
  type: 'number'
}, {
  key: 'itemBorderRadius',
  type: 'number'
}, {
  key: 'itemBorderWidth',
  type: 'number'
}, {
  key: 'itemShadowBlur',
  type: 'number'
}, {
  key: 'itemShadowDirection',
  type: 'number'
}, {
  key: 'itemShadowSize',
  type: 'number'
}, {
  key: 'loadMoreButtonBorderRadius',
  type: 'number'
}, {
  key: 'loadMoreButtonBorderWidth',
  type: 'number'
}, {
  key: 'minItemSize',
  type: 'number'
}, // { key: 'numberOfDisplayedItems', type: 'number' },
{
  key: 'numberOfImagesPerCol',
  type: 'number'
}, {
  key: 'numberOfImagesPerRow',
  type: 'number'
}, {
  key: 'scrollDirection',
  type: 'number'
}, {
  key: 'slideshowInfoSize',
  type: 'number'
}, {
  key: 'textBoxBorderRadius',
  type: 'number'
}, {
  key: 'textBoxBorderWidth',
  type: 'number'
}, {
  key: 'textBoxHeight',
  type: 'number'
}, {
  key: 'textImageSpace',
  type: 'number'
}, // { key: 'textsHorizontalPadding', type: 'number' },
// { key: 'textsVerticalPadding', type: 'number' },
{
  key: 'thumbnailSize',
  type: 'number'
}, // { key: 'titleDescriptionSpace', type: 'number' },
{
  key: 'videoSpeed',
  type: 'number'
}, // ************* color *************
{
  key: 'arrowsColor',
  type: 'color'
}, // { key: 'customButtonBorderColor', type: 'color' },
// { key: 'customButtonColor', type: 'color' },
// { key: 'customButtonFontColor', type: 'color' },
// { key: 'customButtonFontColorForHover', type: 'color' },
{
  key: 'itemBorderColor',
  type: 'color'
}, {
  key: 'itemDescriptionFontColor',
  type: 'color'
}, {
  key: 'itemDescriptionFontColorSlideshow',
  type: 'color'
}, {
  key: 'itemFontColor',
  type: 'color'
}, {
  key: 'itemFontColorSlideshow',
  type: 'color'
}, {
  key: 'itemOpacity',
  type: 'color'
}, {
  key: 'itemShadowOpacityAndColor',
  type: 'color'
}, {
  key: 'loadMoreButtonBorderColor',
  type: 'color'
}, {
  key: 'loadMoreButtonColor',
  type: 'color'
}, {
  key: 'loadMoreButtonFontColor',
  type: 'color'
}, {
  key: 'oneColorAnimationColor',
  type: 'color'
}, {
  key: 'textBoxBorderColor',
  type: 'color'
}, {
  key: 'textBoxFillColor',
  type: 'color'
}, // ************* font *************
{
  key: 'jsonStyleParams',
  type: 'font'
}, // not coming from defaultStyles
{
  key: 'galleryLayoutType',
  type: 'font'
}, // not coming from defaultStyles
{
  key: 'groupTypes',
  type: 'font'
}, {
  key: 'rotatingCropRatios',
  type: 'font'
}, {
  key: 'rotatingGroupTypes',
  type: 'font'
}, // { key: 'customButtonFontForHover', type: 'font' },
{
  key: 'itemDescriptionFont',
  type: 'font'
}, {
  key: 'itemDescriptionFontSlideshow',
  type: 'font'
}, {
  key: 'itemFont',
  type: 'font'
}, {
  key: 'itemFontSlideshow',
  type: 'font'
}, {
  key: 'loadMoreButtonFont',
  type: 'font'
}]; // CASE 2: Style params that have the same name, and the same type, but need a transform function

var TYPE_NUMBER_WITH_TRANSFORMATION = [// { key: 'collageAmount', type: 'number', transformBy: '*10' },
{
  key: 'collageDensity',
  type: 'number',
  transformBy: '*100'
}, // { key: 'floatingImages', type: 'number', transformBy: '*100' },
{
  key: 'thumbnailSpacings',
  type: 'number',
  transformBy: '*2'
}]; // Case 3: PG styleParam of type 'boolean' --> Wix styleParam of type 'number'

var TYPE_BOOLEAN_TO_NUMBER = [{
  key: 'chooseBestGroup',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'cubeImages',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'enableInfiniteScroll',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'isAutoSlideshow',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'isRTL',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'isVertical',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'mobilePanorama',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'placeGroupsLtr',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'playButtonForAutoSlideShow',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'slideshowLoop',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, {
  key: 'smartCrop',
  type: 'number',
  trueValue: 1,
  falseValue: 0
}, // Yes.. it is wierd:
{
  key: 'fullscreen',
  type: 'number',
  trueValue: 0,
  falseValue: 1
} // { key: 'loveCounter', type: 'number', trueValue: 0, falseValue: 1 },
]; // Case 4: PG styleParam of type 'string' --> Wix styleParam of type 'number'

var TYPE_STRING_TO_NUMBER = [// { key: 'allowContextMenu', type: 'number', options: CONVERTER_CONSTS.calculateTextBoxHeightMode },
{
  key: 'expandAnimation',
  type: 'number',
  options: CONVERTER_CONSTS.expandAnimation
}, // { key: 'galleryHorizontalAlign', type: 'number', options: CONVERTER_CONSTS.galleryHorizontalAlign },
// { key: 'gallerySizeType', type: 'number', options: CONVERTER_CONSTS.gallerySizeType },
{
  key: 'galleryThumbnailsAlignment',
  type: 'number',
  options: CONVERTER_CONSTS.galleryThumbnailsAlignment
}, // { key: 'galleryVerticalAlign', type: 'number', options: CONVERTER_CONSTS.galleryVerticalAlign },
{
  key: 'hoveringBehaviour',
  type: 'number',
  options: CONVERTER_CONSTS.hoveringBehaviour
}, {
  key: 'imageHoverAnimation',
  type: 'number',
  options: CONVERTER_CONSTS.imageHoverAnimation
}, {
  key: 'imageInfoType',
  type: 'number',
  options: CONVERTER_CONSTS.imageInfoType
}, {
  key: 'imageLoadingMode',
  type: 'number',
  options: CONVERTER_CONSTS.imageLoadingMode
}, // { key: 'imageLoadingWithColorMode', type: 'number', options: CONVERTER_CONSTS.imageLoadingWithColorMode },
// { key: 'imageRatioType', type: 'number', options: CONVERTER_CONSTS.imageRatioType },
{
  key: 'overlayAnimation',
  type: 'number',
  options: CONVERTER_CONSTS.overlayAnimation
}, {
  key: 'scrollAnimation',
  type: 'number',
  options: CONVERTER_CONSTS.scrollAnimation
}, {
  key: 'slideAnimation',
  type: 'number',
  options: CONVERTER_CONSTS.slideAnimation
}, {
  key: 'titlePlacement',
  type: 'number',
  options: CONVERTER_CONSTS.titlePlacement
}, {
  key: 'videoPlay',
  type: 'number',
  options: CONVERTER_CONSTS.videoPlay
}];

var convertPgStyleParamsToWixStyleParams = function convertPgStyleParamsToWixStyleParams(pgStyleParams) {
  try {
    var wixStyleParams = {};
    SAME_TYPE_AND_SMAE_NAME.forEach(function (_ref) {
      var key = _ref.key,
          type = _ref.type;
      wixStyleParams[key] = buildWixStyleParam.sameTypeSameName(type, pgStyleParams[key]);
    });
    TYPE_NUMBER_WITH_TRANSFORMATION.forEach(function (_ref2) {
      var key = _ref2.key,
          type = _ref2.type,
          transformBy = _ref2.transformBy;
      wixStyleParams[key] = buildWixStyleParam.numberTypeWithTransformation(type, pgStyleParams[key], transformBy);
    });
    TYPE_BOOLEAN_TO_NUMBER.forEach(function (_ref3) {
      var key = _ref3.key,
          type = _ref3.type,
          trueValue = _ref3.trueValue,
          falseValue = _ref3.falseValue;
      wixStyleParams[key] = buildWixStyleParam.booleanToNumber(type, pgStyleParams[key], trueValue, falseValue);
    });
    TYPE_STRING_TO_NUMBER.forEach(function (_ref4) {
      var key = _ref4.key,
          type = _ref4.type,
          options = _ref4.options;
      wixStyleParams[key] = buildWixStyleParam.stringToNumber(type, pgStyleParams[key], options);
    }); // CASE 5: Unique cases of conversion
    // arrowsPosition. options: 0: 'ON_GALLERY' / 1: 'OUTSIDE_GALLERY'

    wixStyleParams.arrowsPosition = {
      type: 'number',
      value: pgStyleParams.showArrows === true ? 1 : 0
    }; // imageResize (wixSp) = cubeType (pgSp). options: 0: 'fill' / 1: 'fit'

    wixStyleParams.imageResize = {
      type: 'number',
      value: pgStyleParams.cubeType === 'fit' ? 1 : 0
    }; // imageOrientation (wixSp) = isVertical (pgSp). options: 0: false / 1: true

    wixStyleParams.imageOrientation = {
      type: 'number',
      value: pgStyleParams.isVertical === true ? 1 : 0
    };
    return wixStyleParams;
  } catch (e) {
    return {};
  }
};

export default convertPgStyleParamsToWixStyleParams;