"use strict";

exports.__esModule = true;
exports.ARTSTORE_PUBSUB_ENUMS = exports.ALBUMS = exports.ART_STORE = exports.privdersIds = exports.providerIntents = void 0;
var providerIntents = {
  configuration: 'configuration',
  shop: 'shop',
  pricing: 'pricing',
  manage: 'manage',
  editor: 'editor',
  checkout: 'checkout',
  thankyou: 'thankyou',
  banner: 'banner',
  cart: 'cart'
};
exports.providerIntents = providerIntents;
var privdersIds = {
  wix: '147bec5f-d87e-d37b-b5cf-36f47c1815b4',
  orderaprint: '14a9911b-a59e-b7f3-f878-3661e0d83f2f',
  local: '14a0ee97-918b-2f0c-43f1-42e67948716b'
};
exports.privdersIds = privdersIds;
var ART_STORE = {
  ARTSTORE_APP_DEFINITION_ID: '147ab90e-91c5-21b2-d6ca-444c28c8a23b',
  GALLERY_WIDGET_ID: '147ab9e6-2166-63ea-f9f1-3e81db0cbb4a',
  CART_WIDGET_ID: '1482a5fd-aaf4-0660-4c80-178d7d8a28f3',
  SENTRY_DSN: 'https://1c9d600630e64cd6b5a4b0cda27df53d@sentry.wixpress.com/113',
  PROVIDERS: {
    LOCAL: '14a0ee97-918b-2f0c-43f1-42e67948716b',
    WIX: '147bec5f-d87e-d37b-b5cf-36f47c1815b4',
    ORDERAPRINT: '14a9911b-a59e-b7f3-f878-3661e0d83f2f'
  }
};
exports.ART_STORE = ART_STORE;
var ALBUMS = {
  ALBUMS_APP_DEF_ID: '13ff8629-c1fc-e289-e81f-bc8c8968e9d6'
};
exports.ALBUMS = ALBUMS;
var ARTSTORE_PUBSUB_ENUMS = {
  USE_OOI_ARTSTORE: 'USE_OOI_ARTSTORE',
  INIT_CART: 'ARTSTORE_OOI_INIT_CART',
  SHOW_OOI_CART: 'SHOW_OOI_CART',
  UPDATE_VERIFICATION_INSTANCE: 'UPDATE_VERIFICATION_INSTANCE',
  OOI_MESSAGE: 'ARTSTORE_OOI_MESSAGE',
  CART_ITEMS_CHANGE: 'ARTSTORE_OOI_CART_ITEMS_CHANGE',
  REFRESH_OOI_CART: 'ARTSTORE_OOI_REFRESH_CART_ITEMS',
  SHOW_CART: 'ARTSTORE_OOI_SHOW_CART',
  OOI_CHECKOUT: 'ARTSTORE_OOI_CHECKOUT',
  ADD_TO_CART: 'ARTSTORE_OOI_ADD_TO_CART',
  UPDATE_CART_ITEM: 'ARTSTORE_OOI_UPDATE_CART_ITEM',
  OPEN_PROVIDER_MODAL: 'ARTSTORE_OOI_OPEN_PROVIDER_MODAL',
  REFRESH_BI_DATA: 'ARTSTORE_OOI_REFRESH_BI_DATA',
  CLEAR_CART: 'ARTSTORE_OOI_CLEAR_CART',
  REMOVE_FROM_CART: 'ARTSTORE_OOI_REMOVE_FROM_CART',
  REFRESH_CART: 'REFRESH_CART',
  CHECKOUT_STATE: 'CHECKOUT_STATE',
  CHECKOUT: 'STORE_CHECKOUT',
  CART: 'STORE_CART',
  EDITOR: 'STORE_EDITOR',
  STORE_POPUP_MESSAGE: 'STORE_POPUP_MESSAGE'
};
exports.ARTSTORE_PUBSUB_ENUMS = ARTSTORE_PUBSUB_ENUMS;