import _ from 'lodash';
export var LAYOUT_TYPE = {
  collage: 0,
  masonry: 1,
  grid: 2,
  thumbnails: 3,
  slider: 4,
  slideShow: 5,
  strip: 6,
  column: 7,
  magic: 8
};
export var SCROLL_DIRECTION = {
  vertical: 0,
  horizontal: 1
};
export var GRID_STYLE = {
  fitToScreen: 0,
  setItemsPerRow: 1
};
export var PUBLIC_STYLE_PARAMS_NAMES = ['galleryLayout', 'scrollDirection', 'galleryImageRatio', 'imageResize', 'galleryThumbnailsAlignment', 'isVertical', 'gallerySize', 'thumbnailSpacings', 'imageMargin', 'numberOfImagesPerCol', 'collageDensity', 'enableInfiniteScroll', 'gridStyle', 'numberOfImagesPerRow'];

var defaultGallery = function defaultGallery(isStoreGallery) {
  return isStoreGallery ? 2 : 0;
};

var getDefaultStyles = function getDefaultStyles(_ref) {
  var isStoreGallery = _ref.isStoreGallery;
  return {
    // user can change - start
    galleryLayout: defaultGallery(isStoreGallery),
    scrollDirection: 0,
    galleryImageRatio: 2,
    imageResize: 0,
    galleryThumbnailsAlignment: 0,
    isVertical: 0,
    gallerySize: 30,
    thumbnailSpacings: 0,
    imageMargin: 10,
    numberOfImagesPerCol: 1,
    collageDensity: 80,
    enableInfiniteScroll: 1,
    gridStyle: 0,
    numberOfImagesPerRow: 3
  };
};

export var getPublicStyleParamsByLayout = function getPublicStyleParamsByLayout(layoutType, _ref2) {
  var _layoutsStyles;

  var _ref2$isStoreGallery = _ref2.isStoreGallery,
      isStoreGallery = _ref2$isStoreGallery === void 0 ? false : _ref2$isStoreGallery;
  layoutType = layoutType || defaultGallery(isStoreGallery);
  var layoutsStyles = (_layoutsStyles = {}, _layoutsStyles[LAYOUT_TYPE.collage] = {
    galleryLayout: LAYOUT_TYPE.collage
  }, _layoutsStyles[LAYOUT_TYPE.masonry] = {
    galleryLayout: LAYOUT_TYPE.masonry
  }, _layoutsStyles[LAYOUT_TYPE.grid] = {
    galleryLayout: LAYOUT_TYPE.grid
  }, _layoutsStyles[LAYOUT_TYPE.thumbnails] = {
    galleryLayout: LAYOUT_TYPE.thumbnails
  }, _layoutsStyles[LAYOUT_TYPE.slider] = {
    galleryLayout: LAYOUT_TYPE.slider
  }, _layoutsStyles[LAYOUT_TYPE.slideShow] = {
    galleryLayout: LAYOUT_TYPE.slideShow
  }, _layoutsStyles.panorama = {
    showArrows: false,
    cubeImages: false,
    isVertical: true,
    galleryType: 'Columns',
    groupSize: 1,
    groupTypes: '1',
    // gallerySize: this.getGalleryWidth(),
    oneRow: false,
    fixedColumns: 1,
    hasThumbnails: false,
    enableScroll: true,
    isGrid: false,
    isColumns: false,
    isSlider: false,
    isSlideshow: false,
    cropOnlyFill: false
  }, _layoutsStyles[LAYOUT_TYPE.column] = {
    galleryLayout: LAYOUT_TYPE.column
  }, _layoutsStyles); // const galleryLayoutsNames = [
  //   'collage',
  //   'masonry',
  //   'grid',
  //   'thumbnails',
  //   'slider',
  //   'slideshow',
  //   'panorama',
  //   'column',
  //   'magic'
  // ];
  // let layoutName = galleryLayoutsNames[galleryLayout || 0];
  // if (isStoreGallery && isUndefined(galleryLayout)) {
  //   layoutName = galleryLayoutsNames[2];
  // }

  var mergedStyleParams = _.merge({}, getDefaultStyles({
    isStoreGallery: isStoreGallery
  }), layoutsStyles[layoutType]);

  var publicStyleParams = _.pick(mergedStyleParams, PUBLIC_STYLE_PARAMS_NAMES);

  var numbers = ['galleryLayout', 'scrollDirection', 'galleryImageRatio', 'imageResize', 'galleryThumbnailsAlignment', 'gallerySize', 'thumbnailSpacings', 'imageMargin', 'numberOfImagesPerCol', 'collageDensity', 'enableInfiniteScroll', 'isVertical', 'gridStyle', 'numberOfImagesPerRow'];
  var booleans = [// allowTitle
  ];
  return {
    numbers: _.pick(publicStyleParams, numbers),
    booleans: _.pick(publicStyleParams, booleans)
  };
};
export var getGalleryLayoutFromStyleParams = function getGalleryLayoutFromStyleParams(styleParams) {
  return styleParams.numbers.galleryLayout || styleParams.numbers.galleryType;
};
export var getVisibileControlsByStyleParams = function getVisibileControlsByStyleParams(styleParams) {
  var visibileControls = [];

  if (styleParams.layout === LAYOUT_TYPE.collage) {
    visibileControls.push('imageOrientation');
    visibileControls.push('scrollDirection');
    visibileControls.push('thumbnailSize');
    visibileControls.push('spacing');
    visibileControls.push('collageDensity');

    if (styleParams.scrollDirection === SCROLL_DIRECTION.vertical) {
      visibileControls.push('enableInfiniteScroll');
    }
  }

  if (styleParams.layout === LAYOUT_TYPE.masonry) {
    visibileControls.push('imageOrientation');
    visibileControls.push('thumbnailSize');
    visibileControls.push('spacing');
    visibileControls.push('enableInfiniteScroll');
  }

  if (styleParams.layout === LAYOUT_TYPE.grid) {
    visibileControls.push('scrollDirection');
    visibileControls.push('thumbnailResize');
    visibileControls.push('thumbnailSpacings');
    visibileControls.push('imageRatio');

    if (styleParams.scrollDirection === SCROLL_DIRECTION.vertical) {
      visibileControls.push('gridStyle');
      visibileControls.push('enableInfiniteScroll');

      if (styleParams.gridStyle === GRID_STYLE.setItemsPerRow) {
        visibileControls.push('imagesPerRow');
      }
    }

    if (styleParams.scrollDirection === SCROLL_DIRECTION.horizontal) {
      visibileControls.push('imagesPerColumn');
    }
  }

  if (styleParams.layout === LAYOUT_TYPE.thumbnails) {
    visibileControls.push('thumbnailsAlignment');
    visibileControls.push('thumbnailSpacings');
  }

  if (styleParams.layout === LAYOUT_TYPE.slider) {
    visibileControls.push('thumbnailResize');
    visibileControls.push('spacing');
    visibileControls.push('imageRatio');
  }

  if (styleParams.layout === LAYOUT_TYPE.strip) {
    visibileControls.push('spacing');
    visibileControls.push('enableInfiniteScroll');
  }

  if (styleParams.layout === LAYOUT_TYPE.column) {
    visibileControls.push('spacing');
  }

  if (styleParams.layout === LAYOUT_TYPE.magic) {
    visibileControls.push('magicLayout');
  }

  return visibileControls;
};